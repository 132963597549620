:root { 
--black_900_7f:#0000007f; 
--indigo_400_aa:#6354c7aa; 
--deep_purple_800:#3e14c3; 
--red_200:#f28f8f; 
--gray_800_02:#474747; 
--gray_800_01:#4c4c4c; 
--black_900_3f:#0000003f; 
--blue_800_01:#2873b9; 
--pink_A100:#e374ab; 
--black_900_01:#0c0c0c; 
--yellow_800:#f79e1b; 
--indigo_50_01:#e5e5fd; 
--gray_200_01:#f0f0f0; 
--blue_gray_900_02:#23364b; 
--blue_gray_900_01:#262a50; 
--blue_gray_700:#464e57; 
--gray_200_02:#e9e9e9; 
--blue_gray_900:#263238; 
--purple_500:#c112ad; 
--red_A700:#eb001b; 
--gray_200_03:#eeedec; 
--purple_500_01:#9a10c0; 
--gray_600:#737373; 
--deep_purple_50_01:#edeaff; 
--gray_400:#c7c7c7; 
--blue_900:#1434cb; 
--blue_gray_100:#d8d8d8; 
--orange_A200:#fbb03b; 
--deep_purple_900_02:#2610c0; 
--gray_800:#404040; 
--gray_200:#ebebeb; 
--deep_purple_900_01:#2910c0; 
--orange_400:#fc9719; 
--deep_purple_50:#efefff; 
--black_900_11:#00000011; 
--gray_400_01:#c5c5c5; 
--gray_400_02:#bbbbbb; 
--gray_100_01:#f7f7f7; 
--black_900_19:#00000019; 
--white_A700:#ffffff; 
--blue_gray_700_01:#495b70; 
--deep_purple_900:#2510c0; 
--blue_gray_100_01:#d9d9d9; 
--blue_gray_100_02:#cecece; 
--red_500:#ff3635; 
--black_900_21:#00000021; 
--black_900_ba:#000000ba; 
--teal_400:#15a39b; 
--black_900:#000000; 
--deep_orange_600:#e9522a; 
--purple_400_01:#ae2ad1; 
--blue_gray_800:#37474f; 
--deep_orange_200:#ffa8a7; 
--black_900_28:#00000028; 
--purple_600:#962a82; 
--purple_400:#b02ad1; 
--gray_900_02:#1a1a1a; 
--gray_700:#666666; 
--gray_900_03:#141414; 
--gray_900_04:#252525; 
--gray_500:#949494; 
--blue_800:#176ba8; 
--indigo_50:#e1e3fc; 
--orange_A700:#ff5f00; 
--gray_900:#1d1d1e; 
--gray_900_01:#1b1b1b; 
--gray_300_03:#e1e1e1; 
--gray_300:#dddddd; 
--gray_300_02:#e8e3e3; 
--gray_300_01:#e0e0e0; 
--teal_A400:#27debf; 
--gray_100:#f1f2fe; 
--deep_purple_800_03:#2a10c0; 
--deep_purple_800_04:#3914c2; 
--deep_purple_800_01:#3412c1; 
--deep_purple_800_02:#3712c1; 
}