/* PayPalButton.css */

.paypal-button {
    display: inline-flex;
    align-items: center;
    padding: 10px 40px;
    background-color: #fdd909;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .paypal-button img {
    margin-right: 10px;
    height: 30px;
    width: 100px;
  }
  