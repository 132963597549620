.game {
    display: flex;
    flex-direction: row;
  }
  
  .game-board {
    display: flex;
    flex-direction: column;
  }
  
  .board-row {
    display: flex;
    flex-direction: row;
  }
  
  .square {

    border: 1px solid #ddd;
    margin-right: -1px;
    margin-top: -1px;
    padding: 0;

    text-align: center;
    background-color: #fff;
    cursor: pointer;
  }
  
  .square:hover {
    background-color: #f5f5f5;
  }
  
  .game-info {
    margin-left: 20px;
  }
  