/* PaymentSuccessScreen.css */

.payment-success-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .success-icon {
    margin-bottom: 20px;
  }
  
  .success-message {
    color: #28a745;
    font-size: 24px;
    font-weight: bold;
  }
  