.inputBoxOuter {
    border-width: 1px;
    border-style: solid;
    border-color: #c1bbbb;
    border-radius: 5px;
    padding: 10px 15px 10px 18px;
    margin-top: -1rem;
}


.form__field-group{
    padding: 2px;
    margin-bottom: 1rem;
}

.label_text_Input {
    color: #000000;
    display: block;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4;
    margin: 0;
    padding: 0;
    text-align: left;
}

.input_field_C{
    width:  100%;
    margin-top: 2px;
}

