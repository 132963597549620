/* PaymentCancelScreen.css */

.payment-cancel-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .cancel-icon {
    margin-bottom: 20px;
  }
  
  .cancel-message {
    color: #dc3545;
    font-size: 24px;
    font-weight: bold;
  }
  